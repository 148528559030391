<template>
    <standard-page :definition="$DEFINITIONS.catalog" title="Catalogs">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
            <span>Catalogs</span>
        </template>
        <template v-slot:buttons>
            <button @click="newCatalog()" class="d-none d-xl-block btn float-right btn-orange rounded font-weight-bold" title="Add Catalog">
                <i class="fas fa-plus-square mg-r-10"/>Add Catalog
            </button>
            <button @click="newCatalog()" class="d-xl-none w-35 btn btn-icon float-right btn-orange rounded font-weight-bold" title="Add Catalog">
                <i class="typcn typcn-document-add"/>
            </button>
        </template>
        <template v-slot:content>
            <data-table :api="api" :columns="columns" :row-click="view" @ready="onReady" list-key="catalogs"></data-table>
            <delete-modal :context="'Catalog'" :service="'identity'" :table="table" :url="`identity/catalogs/${catalogIdtoDelete}`" v-if="catalogIdtoDelete"></delete-modal>

        </template>
    </standard-page>
</template>

<script>
    import EventBus from '@/event-bus';

    export default {
        name: 'Catalogs',
        components: {},
        data() {
            return {
                api: `${this.$identity.defaults.baseURL}/identity/catalogs`,
                columns: [
                    {type: 'name'},
                    {type: 'created'},
                    {type: 'extrinsic'},
                    {
                        title: 'Type',
                        data: 'catalog_type',
                    },
                    {
                        type: 'action',
                        defaultContent: `<div class="offset-2 btn-group btn-icon-list">
                                ${this.$StandardButtons.editButton('Rename Catalog')}
                                ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Delete Catalog')}
                                ${this.$StandardButtons.viewTemplateButton()}
                            </div>
          `
                    },
                ],
                table: null,
                catalog: {
                    name: null
                },
                catalogIdtoDelete: null,
            };
        },

        methods: {
            onReady(table) {
                this.table = table;
                let self = this;

                $(async function () {
                    $('#main-table tbody').on('click', '.delete', function () {
                        let entity = table.row($(this).parents('tr')).data();
                        self.setCatalogIdToDelete(entity.catalog);
                        table.ajax.reload();
                    });

                    $('#main-table tbody').on('click', '.view-template', function () {
                        let entity = table.row($(this).parents('tr')).data();
                        console.log(entity)
                        self.viewTemplate(entity.catalog);
                    });

                    $('#main-table tbody').on('click', '.edit', async function () {
                        let tr = $(this).closest('tr');
                        let cells = tr.children().toArray();
                        let row = self.table.row(tr);
                        let property = row.data();
                        table.columns(1).visible(false);
                        table.columns(2).visible(false);
                        // table.columns(4).visible(false);
                        let nameTd = $(cells[0]);
                        let nameInput = $(`<input class="editor" value="${property.name}"></input>`);
                        nameInput.on('click', function (e) {
                            e.stopPropagation();
                        });
                        nameTd.empty();
                        nameTd.append(nameInput);
                        let typeTd = $(cells[3]);
                        let typeColumnDiv = $(`<div class="edit-fact-container"></div>`);
                        let typeColumnSelect = $(`<select class="form-control fact-type" style="width:150px" placeholder="Type" value="${property.catalog_type}">
                          <option ${property.catalog_type === 'Individual' ? 'selected' : ''} value="Individual">Individual</option>
                          <option ${property.catalog_type === 'Sustainability' ? 'selected' : ''} value="Sustainability">Sustainability</option>
                          <option ${property.catalog_type === 'Personal' ? 'selected' : ''} value="Personal">Personal</option>
                          <option ${property.catalog_type === 'Attestor' ? 'selected' : ''} value="Attestor">Attestor</option>
                          <option ${property.catalog_type === 'Trade' ? 'selected' : ''} value="Trade">Trade</option>
                          </select>`);
                        typeColumnSelect.on('click', function (e) {
                            e.stopPropagation();
                        });
                        typeColumnDiv.append(typeColumnSelect);
                        typeTd.empty();
                        typeTd.append($(typeColumnDiv));
                        let saveBtn = $(`<button class="table-editor-button table-editor-check"><i class="fas fa-check"></i></button>`);
                        let cancelBtn = $(`<button class="table-editor-button table-editor-cancel"><i class="fas fa-times"></i></button>`);
                        let actionTd = $(cells[4]);
                        if (actionTd.toArray().length == 0) {
                            actionTd = $(cells[2]);
                        }
                        let editButton = actionTd.find('.edit');
                        editButton.hide();
                        let deleteButton = actionTd.find('.delete');
                        deleteButton.hide();
                        let viewTemplateBtn = actionTd.find('.view-template');
                        viewTemplateBtn.hide();
                        actionTd.prepend(cancelBtn);
                        actionTd.prepend(saveBtn);
                        actionTd.addClass('text-nowrap');
                        saveBtn.on('click', async function (e) {
                            e.stopPropagation();
                            await self.renameCatalog(property.catalog, nameInput.val(), typeColumnSelect.val());

                            nameTd.text(nameInput.val());
                            typeTd.text(typeColumnSelect.val());
                            saveBtn.remove();
                            cancelBtn.remove();
                            editButton.show();
                            deleteButton.show();
                            viewTemplateBtn.show();
                            if ($('#main-table tbody').find('.table-editor-button').toArray().length == 0) {
                                table.columns(1).visible(true);
                                table.columns(2).visible(true);
                            }
                        });
                        cancelBtn.on('click', function (e) {
                            e.stopPropagation();
                            saveBtn.remove();
                            cancelBtn.remove();
                            nameTd.text(property.name);
                            typeTd.text(property.catalog_type);
                            saveBtn.remove();
                            cancelBtn.remove();
                            editButton.show();
                            deleteButton.show();
                            viewTemplateBtn.show();
                            if ($('#main-table tbody').find('.table-editor-button').toArray().length == 0) {
                                table.columns(1).visible(true);
                                table.columns(2).visible(true);
                            }
                        });
                    })

                });
            },
            view(catalog) {
                this.$router.push({name: 'identities-catalogs-dids', params: {catalogid: catalog.catalog, catalogNameProp: catalog.name}});
            },
            viewTemplate(catalog) {
                this.$router.push({name: 'templates', params: {catalogid: catalog}});
            },
            newCatalog() {
                this.$router.push({name: 'new-identities-catalogs'});
            },

            async renameCatalog(catalog, name, catalog_type) {
                if (name.trim().length > 0) {
                    try {
                        EventBus.$emit('openLoader');
                        await this.$identity.patch(`identity/catalogs/${catalog}`, {
                            name: name,
                            catalog_type: catalog_type
                        });
                        this.$toastr.s(this.$DEFINITIONS.identity.toasts.updateCatalog, 'Success');
                    } catch (e) {
                        console.log(e);
                        this.$toastr.e('Catalog update failed', 'Error');
                    } finally {
                        EventBus.$emit('closeLoader');
                        this.table.ajax.reload();
                    }
                } else {
                    this.$toastr.e('Please provide a name first!', 'Failed');
                }
            },
            setCatalogIdToDelete(catalogid) {
                this.catalogIdtoDelete = catalogid;
            },
        }
    }
</script>

<style scoped>
</style>